<template>
  <article>
    <div>
      <div class="columns">
        <div class="column field">
          <p class="menu-label">
            <span class="title is-6">
              Date Filter
            </span>
          </p>
          <div class="field is-narrow is-pulled-left">
            <v-date-picker v-model="cutOffDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              @input="cutOffDate = $filters.fromISOWithCurrentTime($event.toISOString())"
              :timezone="$filters.getIanaTimezone()">
              <div class="field">
                <div class="control has-icons-left">
                  <input type="text"
                    class="input"
                    readonly
                    placeholder="Received Date"
                    :disabled="isCutOffDate || isReadOnly || value.vendorId === null"
                    :value="isCutOffDate ? cutOffDate : $filters.formatDateTimezone(cutOffDate, $userInfo.locale)">
                  <span class="icon is-small is-left">
                    <i class="mdi mdi-calendar mdi- 18px" />
                  </span>
                </div>
              </div>
            </v-date-picker>
          </div>
          <div class="control">
            <input class="is-checkradio is-box is-primary"
              :class="{ 'has-background-color': isCutOffDate }"
              id="cutOffDate"
              name="cutOffDate"
              type="checkbox"
              :disabled="value.vendorId === null || isReadOnly"
              v-model="isCutOffDate">
            <label class="label"
              for="cutOffDate">No Cut-Off Date</label>
          </div>
        </div>

        <div class="column field">
          <p class="menu-label">
            <span class="title is-6">
              Parts Returned Filter
            </span>
          </p>
          <div class="control">
            <input class="is-checkradio"
              checked
              id="returnedparts"
              type="radio"
              name="FilterType"
              value="1"
              :disabled="value.vendorId === null || isReadOnly"
              v-model="isReturnedPartsValue">
            <label for="returnedparts">Returned Parts</label>
          </div>
          <div class="control">
            <input class="is-checkradio"
              id="receiptedparts"
              type="radio"
              name="FilterType"
              value="2"
              :disabled="value.vendorId === null || isReadOnly"
              v-model="isReturnedPartsValue">
            <label for="receiptedparts">All Receipted Parts</label>
          </div>
        </div>

      </div>
    </div>
  </article>
</template>
<script>
import { NumberFiltersMixin, DateTimeFiltersMixin } from '@/components/mixins/filters'
import _cloneDeep from 'lodash/cloneDeep'
// import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'DealerCreditEntryDetailFilter',
  components: {},
  mixins: [NumberFiltersMixin, DateTimeFiltersMixin],
  props: {
    value: null,
    isReadOnly: Boolean,
    isEntity: Boolean
  },
  data() {
    return {
      isCutOffDate: false,
      cutOffDate:  new Date(),
      innerValue: _cloneDeep(this.value),
      isReturnedPartsValue: '1',
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        this.innerValue = this.value
      },
      deep: true
    },
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    isReturnedPartsValue: function(newVal, oldVal) {
      if (newVal === '1') {
        this.innerValue.returnedParts = true
      } else {
        this.innerValue.returnedParts = false
      }
    },
    cutOffDate: function(newVal, oldVal) {
      if (newVal) {
        this.innerValue.cutOffDate = this.cutOffDate.toISOString()
      } else {
        this.innerValue.cutOffDate = ''
      }
    },
    isCutOffDate: function(newVal, oldVal) {
      if (newVal) {
        this.cutOffDate = null
        this.innerValue.cutOffDate = null
        this.innerValue.isCutOffDate = true
      } else {
        this.cutOffDate = new Date()
        this.cutOffDate.setMonth(this.cutOffDate.getMonth() - 6)
        this.innerValue.cutOffDate = this.cutOffDate.toISOString()
        this.innerValue.isCutOffDate = false
      }
    }
  },
  created() {
    if (this.innerValue.cutOffDate) {
      this.cutOffDate = new Date(this.innerValue.cutOffDate)
    }
  },
  methods: {

  }
}
</script>
