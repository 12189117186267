
<template>
  <app-side-menu :min-width="18"
    :fixed-menu="false">
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Select Vendor
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <multiselect v-if="vendors"
                v-model="selectedVendor"
                track-by="name"
                label="name"
                :show-labels="false"
                :options="vendors"
                :options-limit="assetFilter.pageSize"
                :loading="isVendorLoading"
                :internal-search="false"
                :searchable="true"
                :allow-empty="false"
                :disabled="!enableVendorDropdown"
                @select="selectVendor"
                @remove="removeVendor"
                @search-change="getVendorDropdownFilter"
                placeholder="Select a Vendor">
                <span class="has-text-danger"
                  slot="noResult">Factorer not found.</span>
              </multiselect>
            </div>
          </div>
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import VendorService from '@/views/vendor/VendorService'
import _debounce from 'lodash.debounce'
import Guid from '@/components/Guid'
import Multiselect from 'vue-multiselect'
import DealerCreditEntryRoutes from './route-types'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import StoreMixin from './storeMixin'

export default {
  name: 'DealerCreditEntrySideMenu',
  components: {
    Multiselect,
    AppSideMenu
  },
  mixins: [StoreMixin, NumberFiltersMixin, DateTimeFiltersMixin],
  props: {
    isReadOnly: Boolean,
    value: Object,
    vendorDetail: Object
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      vendors: [],
      vendorsName: [],
      isVendorLoading: false,
      assetFilter: {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      },
      selectedVendor: {
        abn: '',
        id: '',
        name: '',
        totalRows: ''
      }
    }
  },
  computed: {
    routeTypes() {
      return DealerCreditEntryRoutes
    },
    enableVendorDropdown() {
      if (_isEmpty(this.returnRoute)) {
        return true
      }
      return false
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    vendorDetail: {
      handler: function(newVal, oldVal) {
        this.selectedVendor.vendorId = this.vendorDetail.vendorId
        this.selectedVendor.id = this.vendorDetail.vendorId
        this.selectedVendor.name = this.vendorDetail.vendorName
        this.selectedVendor.vendorAbn = this.vendorDetail.vendorAbn
      },
      deep: true
    }
  },
  async created() {
    this.getVendorsDropdown()
    // this.selectedVendor = this.value.selectedVendor !== null ? this.value.selectedVendor : ''
  },
  methods: {
    async selectVendor(selected) {
      this.selectedVendor = selected
      this.innerValue.vendorId = selected.id
      this.vendorDetail.vendorName = selected.name
      this.vendorDetail.vendorAbn = selected.abn
      this.vendorDetail.vendorId = selected.id
      this.$emit('saved', false)
    },
    checkCutOffDate() {
      this.isCutOffDate = false
    },
    removeVendor() {},
    filter() {
      this.$emit('filter')
    },
    reset() {
      this.$emit('reset')
    },
    async getVendorsDropdown() {
      this.isVendorLoading = true
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)
      if (this.assetFilter.id !== Guid.empty()) {
        this.selectedVendor = this.vendors.find(c => c.id === this.innerValue.assetId)
      }
      this.isVendorLoading = false
      return this.vendors
    },
    getVendorDropdownFilter: _debounce(async function(query) {
      this.isVendorLoading = true
      this.assetFilter.name = query
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)
      this.isVendorLoading = false
    }, 500)
  }
}
</script>