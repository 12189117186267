<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <div>
        <input type="text"
          v-model="reason"
          class="input"
          placeholder="Enter Reason">
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="save()"
        data-tooltip="Save">Save</button>
      <button class="button tooltip"
        @click="close()"
        data-tooltip="Cancel">Cancel</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'DelaerCreditEntryOtherReasonModal',
  components: {
    BaseModalEx
  },
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-file-document'
    },
    selectedItem: null
  },
  data() {
    return {
      isActive: _cloneDeep(this.active),
      reason: null
    }
  },
  computed: {},
  watch: {
    active(value) {
      this.isActive = value
    },
    isActive: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.reason = this.value[this.selectedItem].otherReason
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    close() {
      this.isActive = false
      this.reason = null
      this.$emit('cancel', false)
    },
    save() {
     if(this.reason !== null) {
        this.value[this.selectedItem].otherReason = this.reason
     }
      this.isActive = false
      this.reason = null
      this.$emit('cancel', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
