import { TableColumn } from '@/classes'

const DealerCreditEntryColumns = [
  // new TableColumn('', 'action', false, 'desc', 'centered'),
  new TableColumn('PO No', 'poNumber', true, 'desc', 'left'),
  new TableColumn('Quote No', 'quoteNo', true, 'desc', 'left'),
  new TableColumn('Inv No', 'invNo', true, 'desc', 'left'),
  new TableColumn('Inv Date', 'invDate', false, 'desc', 'left'),
  new TableColumn('Rec\'d', 'recd', false, 'asc', 'right'),
  new TableColumn('Return No', 'returnNo', false, 'asc', 'right'),
  new TableColumn('Return Date', 'returnDate', false, 'asc', 'right'),
  new TableColumn('Ret\'d', 'retd', false, 'asc', 'right'),
  new TableColumn('Cred\'d', 'cred', false, 'asc', 'right'),
  new TableColumn('Qty Left', 'qtyLeft', false, 'asc', 'right'),
  new TableColumn('Credit Qty', 'credQty', false, 'asc', 'right'),
  new TableColumn('Exc. GST', 'returnAmount', false, 'asc', 'right'),
  new TableColumn('Part No', 'partNo', false, 'asc', 'right'),
  new TableColumn('Part Description', 'script', false, 'asc', 'right'),
  //new TableColumn('Clear Qty', 'clearQuantity', rue, 'asc', 'right'),
  //new TableColumn('Clear Value', 'clearValue', false, 'asc', 'right'),
  new TableColumn('Return Reason', 'reason', false, 'asc', 'right'),
  new TableColumn('Other Fee', 'otherFee', false, 'asc', 'right'),
  new TableColumn('Other Reason', 'otherReason', false, 'asc', 'right')

]
export { DealerCreditEntryColumns }