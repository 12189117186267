<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <!-- <slot name="text-content"></slot> -->
      <dealer-credit-entry-attachments v-model="value"
        :read-only="readOnly"
        :credit-lines="creditLines" />
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="close()"
        data-tooltip="Close">
        <span>Close</span>
      </button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import DealerCreditEntryAttachments from './DealerCreditEntryAttachments'
import _cloneDeep from 'lodash/cloneDeep'
// import { mapGetters } from 'vuex'
// import Guid from '@/components/Guid'
// import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'DealerCreditEntryAttachmentsModal',
  components: {
    BaseModalEx,
    DealerCreditEntryAttachments
  },
  props: {
    value: null,
    readOnly: Boolean,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-paperclip'
    },
    creditLines: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isActive: _cloneDeep(this.active),
      innerValue: null
    }
  },
  computed: {},
  watch: {
    active(value) {
      this.isActive = value
    },
    isActive: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    close() {
      this.isActive = false
      this.$emit('cancel', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
