<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-print="entitySummaries.length > 0"
      :show-delete="false"
      :show-cancel="showCancel"
      :show-save="!readOnlyView && entitySummaries.length > 0"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <dealer-credit-entry-side-menu v-model="filter"
          :vendor-detail="creditDetail"
          :is-read-only="isReadOnly"
          @saved="updateSave($event)"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-horizontal is-15">
          <div class="tile is-parent">
            <article class="tile is-child box detail-page-tile">
              <dealer-credit-entry-detail-filter v-model="filter"
                :is-read-only="isReadOnly"
                :is-entity="isEntity" />
            </article>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child box detail-page-tile">
              <dealer-credit-entry-detail-value v-model="creditDetail"
                :credit-lines="entitySummaries"
                :is-read-only="isReadOnly"
                :is-entity="isEntity" />
            </article>
          </div>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <dealer-credit-entry-detail v-model="entitySummaries"
              :vendor-detail="creditDetail"
              :is-read-only="isReadOnly"
              :is-entity="isEntity" />
          </article>
        </div>
      </div>
    </div>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
  </div>
</template>

<script>
// import DealerCreditEntryRoutes from '../dealercreditentry/route-types'
// import HttpStatus from '@/components/http-status'
// import EventBus from '@/components/EventBus'
// import _isEmpty from 'lodash/isEmpty'
// import PrintPreviewRoutes from '@/components/printpreview/route-types'
// import { Emailer } from '@/classes'
// import { KeyValuePairModel } from '@/classes/viewmodels'

import DealerCreditEntrySideMenu from './DealerCreditEntrySideMenu'
import DealerCreditEntryDetailFilter from './DealerCreditEntryDetailFilter'
import DealerCreditEntryDetailValue from './DealerCreditEntryDetailValue'
import DealerCreditEntryDetail from './DealerCreditEntryDetail'
import DealerCreditEntryValidation from './DealerCreditEntryValidation'
import DealerCreditEntryService from './DealerCreditEntryService'
import DealerCreditEntryRoutes from './route-types'
import _isEmpty from 'lodash/isEmpty'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { UnsavedModal } from '@/components/BulmaModal'
import StoreUtil from '@/store/utils'
import AppDetailHeader from '@/components/AppDetailHeader'
import { AppHeaderButtonTypes } from '@/enums'
import StoreMixin from './storeMixin'
import UploadService from '@/components/FileUploadService'
import Guid from '@/components/Guid'

export default {
  name: 'DealerCreditEntryView',
  components: {
    AppDetailHeader,
    DealerCreditEntrySideMenu,
    DealerCreditEntryDetailFilter,
    DealerCreditEntryDetailValue,
    DealerCreditEntryDetail,
    UnsavedModal
  },
  mixins: [StoreMixin, DealerCreditEntryValidation],
  data() {
    return {
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isSkipSave: false,
      isSave: false,
      isSaveContinue: false,
      isReadOnly: false,
      isEntity: false,
      filter: {
        vendorId: null,
        isCutOffDate: false,
        cutOffDate: new Date(),
        returnedParts: true,
        creditorInvoiceId: null
      },
      creditDetail: {
        vendorId: '',
        vendorName: '',
        vendorAbn: '',
        creditNo: '',
        creditorInvoiceId: '',
        creditDate: new Date()
      },
      entitySummaries: [],
      activeItems: [],
      attachments: [],
      isSavePrint: false
    }
  },
  computed: {
    validateError() {
      return this.$v.$error || this.$v.detailGroup.$error || this.$v.detailGroup.$invalid
    },
    showCancel() {
      if (this.readOnlyView && !_isEmpty(this.returnRoute) && this.entitySummaries.length > 0) {
        return true
      }
      return false
    }
  },
  watch: {
    'filter.vendorId'(newVal, oldVal) {
      if (newVal) {
        this.getData(true)
      }
    },
    'filter.cutOffDate'(newVal, oldVal) {
      if (newVal) {
        this.getData(false)
      }
    },
    'filter.isCutOffDate'(newVal, oldVal) {
      if (newVal) {
        this.getData(false)
      }
    },
    'filter.returnedParts'(newVal, oldVal) {
      this.getData(false)
    },
    'creditDetail.vendorName'(newVal, oldVal) {
      if (newVal) {
        this.entitySummaries.forEach((i) => (i.vendorName = newVal))
      }
    }
  },
  created() {
    this.filter.cutOffDate.setMonth(this.filter.cutOffDate.getMonth() - 6)
    this.filter.cutOffDate = this.filter.cutOffDate.toISOString()

    if (this.readOnlyView && this.$route.params.creditorInvoiceId !== undefined) { // from Creditor List
      this.isReadOnly = true
      this.filter.creditorInvoiceId = this.$route.params.creditorInvoiceId
      this.filter.isCutOffDate = true
      this.filter.returnedParts = true
    } else {
      this.setReadOnlyView(false)
      this.isReadOnly = false
    }
    this.getData(true)
  },
  mounted() {
    // this.getData()
  },
  beforeDestroy() {
    if (_isEmpty(this.returnRoute)) {
      this.setReadOnlyView(false)
    }
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          this.entity.deleted = true
          this.save(true)
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    updateSave: function (value) {
      this.isReadOnly = value
      this.setReadOnlyView(value)
      this.creditDetail.creditNo = ''
      if (!value) {
        this.filter.creditorInvoiceId = null
      }
    },
    async getData(reset) {
      this.isTableLoading = true
      var date = ''
      // this.filter.creditorInvoiceId = ''
      this.isSavePrint = false
      if (this.filter.cutOffDate != '') {
        date = new Date(
          new Date(this.filter.cutOffDate).getFullYear(),
          new Date(this.filter.cutOffDate).getMonth(),
          new Date(this.filter.cutOffDate).getDate()
        ).toISOString()
      }
      // Delete Attachments
      if (!this.isSave && this.entitySummaries.length > 0 && reset) {
        const req = await DealerCreditEntryService.getAttachments(this.entitySummaries[0].creditorInvoiceID)
        this.attachments = req.data.list
        if (this.attachments.length > 0) {
          for (var i = 0; i < this.attachments.length; i++) {
            await UploadService.delete(this.attachments[i].id)
          }
        }
      }

      if (reset) {
        this.activeItems = await DealerCreditEntryService.getEntitySummaries(this.filter)

        // this.entitySummaries = this.activeItems.filter(i => new Date(i.invDate).toISOString() >= date && i.creditReturnID != Guid.empty())
      }

      if (this.activeItems.length > 0 && !this.readOnlyView) {
        if (this.filter.isCutOffDate) {
          this.entitySummaries = this.activeItems.filter((i) =>
            this.filter.returnedParts ? i.creditReturnID != Guid.empty() : i.creditReturnID === Guid.empty() || i.creditReturnID != Guid.empty()
          )
        } else {
          this.entitySummaries = this.activeItems.filter(
            (i) =>
              new Date(i.invDate).toISOString() >= date &&
              (this.filter.returnedParts ? i.creditReturnID != Guid.empty() : i.creditReturnID === Guid.empty() || i.creditReturnID != Guid.empty())
          )
        }
      } else {
        this.entitySummaries = this.activeItems
        if (this.entitySummaries.length !== 0 && this.readOnlyView) {
          let creditHeader = this.entitySummaries[0]
          this.creditDetail.vendorId = creditHeader.vendorID
          this.creditDetail.vendorName = creditHeader.vendorName
          this.creditDetail.vendorAbn = creditHeader.vendorAbn
          this.creditDetail.creditNo = creditHeader.creditNo
          this.creditDetail.creditorInvoiceId = creditHeader.creditorInvoiceId
          this.creditDetail.creditDate = creditHeader.creditDate + 'Z'
          this.filter.isCutOffDate = true
        }
      }

      // if(reset)
      // {
      //   this.activeItems = await DealerCreditEntryService.getEntitySummaries(this.filter)
      //   this.entitySummaries = this.activeItems.filter(i => new Date(i.invDate).toISOString() >= date && i.creditReturnID != Guid.empty())
      // }
      // else
      // {
      //   if(this.activeItems.length > 0)
      //   {
      //     if(this.filter.isCutOffDate)
      //     {
      //       this.entitySummaries = this.activeItems.filter(i => this.filter.returnedParts ? i.creditReturnID != Guid.empty() : (i.creditReturnID === Guid.empty() || i.creditReturnID != Guid.empty()))
      //     }
      //     else
      //     {
      //       this.entitySummaries = this.activeItems.filter(i => new Date(i.invDate).toISOString() >= date && (this.filter.returnedParts ? i.creditReturnID != Guid.empty() : (i.creditReturnID === Guid.empty() || i.creditReturnID != Guid.empty())))
      //     }
      //   }
      // }
      // this.creditDetail.creditorInvoiceId = this.entitySummaries[0].creditorInvoiceID
      // this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0

      if (this.entitySummaries.length > 0) {
        this.creditDetail.creditorInvoiceId = this.entitySummaries[0].creditorInvoiceID
        this.isEntity = true
      } else {
        this.creditDetail.creditorInvoiceId = ''
        this.isEntity = false
      }
      this.isSave = false
      this.isTableLoading = false
      this.$hideSpinner()
    },
    persistFilter() {
      sessionStorage.setItem(this.filter, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filter))
      if (filter) {
        this.filter = filter
      }
    },
    filterList() {
      this.filter.pageIndex = 1
      // sessionStorage.setItem(this.$route.meta.fkey, JSON.stringify(this.filter))
      this.persistFilter()
      this.getData(false)
    },
    resetList() {
      this.filter = {
        vendorId: '',
        cutOffDate: '',
        returnedParts: true,
        creditorInvoiceId: ''
      }
      this.persistFilter()
      this.getData(false)
    },
    confirmSave() {
      this.isConfirmModalActive = true
    },

    async saveEntity(confirmSave) {},
    //Save
    async save(isDelete) {
      this.$showSpinner('Saving...')
      const title = 'Dealer Credit Entry'

      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        this.$hideSpinner()
        return false
      }
      let isCheck = this.entitySummaries.find((i) => i.credQty > i.qtyLeft)
      if (isCheck) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Credit Quantity cannot be more than Quantity Left')
        this.$hideSpinner()
        return false
      }

      // isCheck = this.entitySummaries.filter(i => i.credQty === 0)

      // if (isCheck.length === this.entitySummaries.length) {
      //   this.$notification.openNotificationWithType('danger', title, 'Validation errors. Credit Quantity cannot be 0')
      //   this.$hideSpinner()
      //   return false
      // }

      let creditEntity = this.entitySummaries.filter((i) => i.credQty !== 0)
      if (creditEntity.length === 0) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Credit Quantity cannot be 0')
        this.$hideSpinner()
        return false
      }
      creditEntity.forEach((i) => (i.creditNo = this.creditDetail.creditNo))
      creditEntity.forEach((i) => (i.vendorAbn = this.creditDetail.vendorAbn))
      creditEntity.forEach((i) => (i.vendorName = this.creditDetail.vendorName))
      creditEntity.forEach((i) => (i.creditDate = this.creditDetail.creditDate.toISOString().split('.')[0] + 'Z'))

      // this.saveSnapshot(_cloneDeep(this.entitySummaries))
      this.entitySummaries = await DealerCreditEntryService.postEntity(creditEntity)
      if (this.entitySummaries.length > 0) {
        this.filter.creditorInvoiceId = this.entitySummaries[0].creditorInvoiceID
      }

      if (this.isSaveContinue) {
        // this.print1()
        this.isSave = true
        this.isReadOnly = true
        this.setReadOnlyView(true)
        this.$notification.success('Dealer Credit Entry', isDelete ? 'Dealer Credit Entry set to In-Active' : 'Save successful')
        this.$hideSpinner()
        return true
      }
      this.isSave = true
      this.isReadOnly = true
      this.setReadOnlyView(true)
      this.$notification.success('Dealer Credit Entry', isDelete ? 'Dealer Credit Entry set to In-Active' : 'Save successful')
      this.$hideSpinner()
    },

    //Save And Continue Function
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.isSavePrint = true
        this.print1()
        // this.cancel()
      }
    },

    //Print Function
    print1() {
      let isCheck = this.entitySummaries.filter((i) => i.credQty > 0)
      if (isCheck.length > 0 && this.creditDetail.creditNo.length > 0 && !this.isSavePrint) {
        this.isUnsavedModalActive = true
      } else {
        this.$showSpinner()
        let cutOff
        let returnPart
        let cutOffDate
        let creditorInvoiceID

        if (!this.filter.isCutOffDate) {
          cutOff = 1
          cutOffDate = this.filter.cutOffDate
        } else {
          cutOff = 0
          cutOffDate = ''
        }

        if (this.filter.returnedParts) {
          returnPart = 1
        } else {
          returnPart = 0
        }

        if (this.filter.creditorInvoiceId || this.filter.creditorInvoiceId !== '') {
          creditorInvoiceID = this.filter.creditorInvoiceId
        } else {
          creditorInvoiceID = 'NULL'
        }
        const params = Object.assign({
          CompanyID: this.$userInfo.companyId,
          VendorID: this.filter.vendorId !== null ? this.filter.vendorId : this.creditDetail.vendorId,
          COMPANYNAME: this.$userInfo.companyName,
          REPORTTITLE: 'DEALER CREDIT ENTRY',
          CutOFF: cutOff,
          CutOffDate: cutOffDate,
          CreditorInvoiceID: creditorInvoiceID,
          ReturnedParts: returnPart
        })

        const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
        this.addReportParameters(keyValuePairs)
        const parameterId = this.$guid.newGuid()
        StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)
        const emailer = new Emailer()

        emailer.subject = 'Dealer Credit Entry'
        emailer.reportName = 'AT_DealerCreditEntry'
        this.addEmailer(emailer)
        StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
        const routeData = this.$router.resolve({
          name: PrintPreviewRoutes.PrintPreview.name,
          params: { reportName: 'AT_DealerCreditEntry' },
          query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
        })
        window.open(routeData.href, '_blank')
        this.$hideSpinner()
        // window.open(printPreviewRoute.href, '_blank')
      }
    },

    //Generate XML for Print
    generatePartsXml() {},

    //Skip Button
    async skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      //Delete Attachments
      const req = await DealerCreditEntryService.getAttachments(this.entitySummaries[0].creditorInvoiceID)
      this.attachments = req.data.list
      if (this.attachments.length > 0) {
        for (var i = 0; i < this.attachments.length; i++) {
          await UploadService.delete(this.attachments[i].id)
        }
      }
      this.cancel()
    },

    //Cancel
    cancel() {
      // this.saveSnapshot(_cloneDeep(this.entitySummaries))
      let isCheck = this.entitySummaries.find((i) => i.credQty > 0)
      if (!this.isReadOnly && isCheck && !this.isSkipSave && !this.isSaveContinue && !this.isSave) {
        this.isUnsavedModalActive = true
      } else {
        if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else if (this.returnUrl) {
          window.location.href = `${process.env.VUE_APP_ROOT_URI}${this.returnUrl}`
        }
      }
    },
    //Redirect Route
    redirectToRoute() {},

    //Close Modal
    closeModal() {
      this.isUnsavedModalActive = false
    },

    //Reload Data
    async reloadData() {},
    persistQueries() {},
    removeQueries() {},
    clearSessionStorage() {},
    replaceRoute(id) {
      const newMeta = Object.assign(this.$route.meta, {
        returnUrl: this.returnUrl,
        type: this.type
      })
      this.$router.replace({
        name: DealerCreditEntryRoutes.DealerCreditEntryView.name,
        params: { CompanyId: id },
        meta: newMeta
      })
    },
    clearRouteMeta() {
      // Reset returnUrl meta in case user clicked on other V2
      if (this.$route.meta.returnUrl) {
        this.$route.meta.returnUrl = null
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    // this.saveSnapshot(_cloneDeep(this.entitySummaries))
    let isCheck = this.entitySummaries.find((i) => i.credQty > 0)
    if (this.entitySummaries.length > 0) {
      const req = await DealerCreditEntryService.getAttachments(this.entitySummaries[0].creditorInvoiceID)
      this.attachments = req.data.list
    }

    if (!this.isReadOnly && (this.attachments.length > 0 || isCheck) && !this.isSkipSave && !this.isSaveContinue && !this.isSave) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      // Clean vuex store for now
      // this.clearSnapshots(this.entitySummaries.VendorID)
      this.clearSessionStorage()
      this.clearRouteMeta()
      this.setReturnRoute({})
      next()
    }
  }
}
</script>